<template>
  <div>
      <div class="row g-3 align-center">
            <div class="col-lg-5">
                <div class="form-group">
                    <label class="form-label float-right" for="accountName"><em class="icon ni ni-building"/> Account Name</label>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-input v-model="account.accountName" type="text" :class="{ 'is-error' : $v.account.accountName.$invalid }" id="accountName"/>
                        <div class="is-error" v-if="$v.account.accountName.$invalid">Account Name too short!</div>
                    </div>
                </div>
            </div>
            <button @click="save()">Save</button>
        </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    validations: {
        account: {
            accountName: {
                required,
                minLength: minLength(4)
            }
        }
    },
    data(){
        return {
            account: {
                accountName: ''
            }
            
        }
    },
    created() {
        this.getView()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/validationTest')
        .then(() => { 
        })
        .catch(() => { 
        })},
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                alert('ERROR') 
            } else {
                // do your submit logic here
                //this.submitStatus = 'PENDING'
                alert('OK')
            }
        }
    },
}
</script>

<style scoped>
.is-error {
    color: red;
}
</style>